import $ from 'jquery';
import validate from 'jquery-validation';
import Swal from 'sweetalert2';

import {Calendar} from '@fullcalendar/core';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

import itLocale from '@fullcalendar/core/locales/it';
import 'select2';

require('jquery-form');
// require('datatables.net');

global.msg_warning = "Attenzione!";
global.msg_delete_empty = "Nessun elemento selezionato";
global.msg_delete_block = "Attenzione è stata selezionata una riga che non può essere eliminata";
global.msg_delete_block_link = "Attenzione è stata selezionata una riga con item collegati, non può essere eliminata";
global.msg_delete_confirm = "Stai per eliminare gli elementi selezionati, vuoi continuare?";
global.msg_delete_force = "Attenzione, Vuoi forzare l'eliminazione?. Questa operazione potrebbe compromettere la tracciabilità dei dati";
global.msg_link_confirm = "Stai per collegare gli elementi selezionati, vuoi continuare?";
global.msg_unlink_confirm = "Stai per scollegare gli elementi selezionati, vuoi continuare?";
global.msg_success = "Operazione completata correttamente";
global.msg_danger = "Operazione non completata";
global.msg_error = "Si è verificato un errore";

global.select2_options = {
    allowClear: true,
    placeholder: "Seleziona",
    width: '100%',
    theme: "bootstrap-5",
    containerCssClass: 'select-xs'
}

global.swal_options_delete = {
    title: msg_warning,
    text: msg_delete_confirm,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
};
global.swal_options_error = {
    type: 'error',
    title: msg_error,
    showConfirmButton: false,
    timer: 3500,
};

global.validation_options = {
    // lang: 'it',
    // errorElement: "em",
    // errorPlacement: function (error, element) {
    //     error.addClass("invalid-feedback");
    //     if (element.prop("type") === "checkbox") {
    //         error.insertAfter(element.next("label"));
    //     } else if (element.data('select2-id')) {
    //         error.insertAfter(element.next("span.select2"));
    //     } else if (element.prop("type") === "radio") {
    //         $('input[name="' + element.attr('name') + '"]').each(function (i, e) {
    //             $(e).addClass("is-invalid");
    //         });
    //         error.appendTo(element.parents(".radio-group"));
    //     } else {
    //         error.insertAfter(element);
    //     }
    // },
    // highlight: function (element, errorClass, validClass) {
    //     if ($(element).data('select2-id')) {
    //         $(element).next("span.select2").children('span.selection').children('span.select2-selection').addClass('is-invalid').removeClass('is-valid');
    //     } else {
    //         $(element).addClass("is-invalid").removeClass("is-valid");
    //     }

    // },
    // unhighlight: function (element, errorClass, validClass) {
    //     var elm = $(element);
    //     if (elm.data('select2-id')) {
    //         elm.next("span.select2").children('span.selection').children('span.select2-selection').addClass('is-valid').removeClass('is-invalid');
    //     } else if (elm.prop("type") === "radio") {
    //         $('input[name="' + elm.attr('name') + '"]').each(function (i, e) {
    //             $(e).removeClass("is-invalid");
    //         });
    //     } else {
    //         elm.addClass("is-valid").removeClass("is-invalid");
    //     }
    // }
}

global.onSuccessAjaxForm = function (response, form, textStatus, jqXHR) {
};
global.beforeAjaxForm = function (arr, $form, options) {
};
let ajaxBeforeCallback = function (callback, arr, form, options) {
    callback(arr, form, options);
}
let ajaxFormCallback = function (callback, responseText, textStatus, jqXHR, form) {
    callback(responseText, form, textStatus, jqXHR);
}

global.initSelect2 = function (target, options, val) {

    let obj = $(target);

    obj.select2(options).on("change", function (e) {
        try {
            if ($(this).rules() != undefined) {
                $(this).valid();
            }
        } catch (e) {
            return;
        }
    });

    if (val != undefined) {
        obj.val(val).trigger('change');
    }

}
global.clearSelect2 = function (target) {
    $(target).val(null).trigger('change');
}
global.setValueSelect2 = function (target, val) {
    $(target).val(val).trigger('change');
}

$.validator.setDefaults({
    // lang: 'it',
    highlight: function (element) {
        if ($(element).hasClass('select2-hidden-accessible')) {
            $(element).next("span.select2").children('span.selection').children('span.select2-selection').addClass('is-invalid').removeClass('is-valid');
        } else {
            $(element).addClass("is-invalid").removeClass("is-valid");
        }
    }, unhighlight: function (element) {
        var elm = $(element);
        if (elm.hasClass('select2-hidden-accessible')) {
            elm.next("span.select2").children('span.selection').children('span.select2-selection').addClass('is-valid').removeClass('is-invalid');
        } else if (elm.prop("type") === "radio") {
            $('input[name="' + elm.attr('name') + '"]').each(function (i, e) {
                $(e).removeClass("is-invalid");
            });
        } else {
            elm.addClass("is-valid").removeClass("is-invalid");
        }
    }, errorElement: 'em', // errorClass: 'help-block',
    errorPlacement: function (error, element) {
        error.addClass("invalid-feedback");
        if (element.prop("type") === "checkbox") {
            error.insertAfter(element.next("label"));
        } else if (element.hasClass('select2-hidden-accessible')) {
            error.insertAfter(element.next("span.select2"));
        } else if (element.prop("type") === "radio") {
            $('input[name="' + element.attr('name') + '"]').each(function (i, e) {
                $(e).addClass("invalid-feedback is-invalid");
            });
            error.appendTo(element.parents(".radio-group"));
        } else {
            error.insertAfter(element);
        }
    }, ignore: ".summernote"
});

global.init = function () {
    // if ($().validate != undefined) {
    $('.form.default-validate').each(function () {
        $(this).validate(validation_options);
    });

    // initSelect2('.select2', select2_options);

    $('form.ajax-form').ajaxForm({
        dataType: 'json',
        beforeSubmit: function (arr, $form, options) {
            ajaxBeforeCallback(beforeAjaxForm, arr, $form, options);
        },
        success: function (responseText, textStatus, jqXHR, form) {
            // if (responseText.code == 1) {
            //     notify('success', msg_success);
            // } else {
            //     notify('danger', msg_danger);
            // }
            ajaxFormCallback(onSuccessAjaxForm, responseText, textStatus, jqXHR, form);
        }
    });

    $("body").on("click", ".btn-delete", function (e) {

        e.preventDefault();

        var form_id = $(this).data("form_id");
        var obj = $(this);
        var form = obj.closest('form');
        var dt = form.closest('table.dataTable');

        Swal.fire(swal_options_delete).then(function (result) {
            if (result.value) {
                form.ajaxSubmit({
                    beforeSubmit: function (formData, jqForm, options) {
                        ajaxBeforeCallback(beforeAjaxForm, formData, jqForm, options);
                    },
                    success: function (responseText, statusText, xhr, $form) {
                        if (responseText.code > 0) {
                            ajaxFormCallback(onSuccessAjaxForm, responseText, statusText, xhr, form);
                        } else {
                            Swal.fire(swal_options_error);
                        }
                    }
                });
            }
        });
    });
}

global.initCalendar = function (div_calendar, options) {
    options.plugins = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, multiMonthPlugin, bootstrap5Plugin, interactionPlugin];
    options.locale = itLocale;
    options.themeSystem = 'bootstrap5';

    let calendarEl = document.getElementById(div_calendar);
    let c = new Calendar(calendarEl, options);
    c.render();
    return c;
}
$(document).ready(function ($) {
    init();
});

